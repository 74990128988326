import {useState, useEffect} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import axios from 'axios'

function Table({sect}) { 

    const url = "https://pcaatportal.com/db_copy2.php";
    const [arr, setArr] = useState([]);
    const [SEC, setSec] = useState();
    const [Strand, setStrand] = useState();
    const [count, setCount] = useState();

    const navigate = useNavigate();

    function callback(arr){
        return arr.SEC12 == sect;
    }

    useEffect(()=>{

            console.log(sect.toUpperCase())
            
            let getData = new FormData();
            getData.append('section2', sect);
            axios({
                method: 'POST',
                data: getData,
                url: url
            }).then((response)=>{
                console.log(response.data)
                //setArr(response.data)
               
                setArr((response.data).filter((item)=>{
                            return(item.SEC12 ==  sect.toUpperCase())
                        }))
                console.log(arr)
                setSec(response.data[0].SEC12)
                setStrand(response.data[0].STRAND)
                setCount((response.data).filter((item)=>{
                    return(item.SEC12 ==  sect.toUpperCase())
                }).length)
                
            })
       },[])
 
    const gotoForm = (e) =>{
        //console.log(e.currentTarget.id);
        sessionStorage.setItem('stud',JSON.stringify(arr.filter((item)=>{
            return (item.LRN == e.currentTarget.id)
        })))

         navigate('/form2');
        
    }

    return(
        <center>
      
        <h3 style={{font:"serif"}}>SECTION: {SEC}  <br/>       TRACK & STRAND: {Strand} <br/> Total: {count}</h3>   
        <button onClick={()=> navigate('/second')}>Go back to section list</button><br/><br/>
            <table key={SEC} className='container' style={{border: "1px solid black", width: "100%", backgroundColor: "rgb(255,255,255)"}}>
            <thead style={{border: "1px solid black", width: "100%", backgroundColor: "rgb(212,238,255)"}}>
                <th>FORM</th>
                <th>SURNAME</th>
                <th>FIRST NAME</th>
                <th>MIDDLE NAME</th>
                <th>LRN</th>
            </thead>
             {arr.map((item)=>{
            return( 
               
                <tr >
                    <td style={{border: "1px solid black", margin: "5px"}}>
                    <button id = {item.LRN} onClick={gotoForm} style={{backgroundColor:"yellow"}}>Print SF10</button>
                    </td>
                
                    <td  style={{border: "1px solid black", margin: "5px"}} >{item.LN}</td>
                    <td  style={{border: "1px solid black", margin: "5px"}} >{item.FN}</td>
                    <td  style={{border: "1px solid black", margin: "5px"}} >{item.MN}</td>
                    <td  style={{border: "1px solid black", margin: "5px"}} >{item.LRN}</td>                                   
                </tr> 
                            
            )
            })}
            </table>
        </center>
 
    )
}

export default Table;
