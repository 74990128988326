import Table from "./components/Table";
import Table2 from "./components/Table2";
import Form from "./components/Form";
import Form2 from "./components/Form2";
import Sections from "./components/Sections";
import Sections2 from "./components/Sections2";

import Home from "./components/Home";
import {BrowserRouter, Routes, Route} from 'react-router-dom'
import { useState } from "react";

function App() {

  const [sec, setSec] = useState();
  const [sec2, setSec2] = useState();

  return (
    <BrowserRouter >
    <Routes>
      <Route path='/' element={<Home/>} />

      <Route path='/second' element={<Sections2 sec={sec2} setSec={setSec2}/>} />
      
      <Route path='/third' element={<Sections sec={sec} setSec={setSec}/>} />
      <Route path={'/'+ sec} element={<Table sect={sec}/>} />

      <Route path={'/second/'+ sec2 } element={<Table2 sect={sec2}/>} />
      
      <Route path='/form' element={<Form />} />
      <Route path='/form2' element={<Form2 />} />

    </Routes>
     
    </BrowserRouter>
  );
}

export default App;
